import React from 'react';
import { twMerge } from 'tailwind-merge';

interface TooltipProps {
  message: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ message, children }) => {
  return (
    <div className={twMerge('relative h-19')}>
      {children}
      <div
        className={twMerge(
          'absolute mb-2 right-10 top-7 text-white text-xs rounded p-3 bg-red',
          message ? 'block' : 'hidden'
        )}
      >
        {message}
        <div className="absolute left-1/2 transform -translate-x-1/2 -top-1 w-0 h-0 border-l-4 border-l-transparent border-r-4 border-r-transparent border-b-4 border-b-red"></div>
      </div>
    </div>
  );
};

export default Tooltip;
