/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Game studio API
 * Game studio API documentation OAS.
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ChangeUserDetailCommand,
  ChangeUserPasswordCommand,
  CreateUserCommand,
  GetApiUsersFulltextParams,
  GetApiUsersParams,
  IdentityRole,
  ModifyUserRolesCommand,
  ResetUserPasswordCommand,
  ResetUserPasswordEmailCommand,
  Unit,
  UserDetailDto,
  UserDtoPaginatedList
} from '../../backoffice-schemas'
import { customInstance } from '../../../lib/customInstance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getApiUsers = (
    params?: GetApiUsersParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<UserDtoPaginatedList>(
      {url: `/api/Users`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiUsersQueryKey = (params?: GetApiUsersParams,) => {
    return [`/api/Users`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiUsersQueryOptions = <TData = Awaited<ReturnType<typeof getApiUsers>>, TError = unknown>(params?: GetApiUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsers>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiUsersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUsers>>> = ({ signal }) => getApiUsers(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiUsers>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetApiUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUsers>>>
export type GetApiUsersQueryError = unknown


export function useGetApiUsers<TData = Awaited<ReturnType<typeof getApiUsers>>, TError = unknown>(
 params: undefined |  GetApiUsersParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsers>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiUsers>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetApiUsers<TData = Awaited<ReturnType<typeof getApiUsers>>, TError = unknown>(
 params?: GetApiUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsers>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiUsers>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetApiUsers<TData = Awaited<ReturnType<typeof getApiUsers>>, TError = unknown>(
 params?: GetApiUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsers>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useGetApiUsers<TData = Awaited<ReturnType<typeof getApiUsers>>, TError = unknown>(
 params?: GetApiUsersParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsers>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetApiUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const postApiUsers = (
    createUserCommand: CreateUserCommand,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<UserDetailDto>(
      {url: `/api/Users`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createUserCommand, signal
    },
      options);
    }
  


export const getPostApiUsersMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUsers>>, TError,{data: CreateUserCommand}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiUsers>>, TError,{data: CreateUserCommand}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUsers>>, {data: CreateUserCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiUsers(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUsersMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUsers>>>
    export type PostApiUsersMutationBody = CreateUserCommand
    export type PostApiUsersMutationError = unknown

    export const usePostApiUsers = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUsers>>, TError,{data: CreateUserCommand}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUsers>>,
        TError,
        {data: CreateUserCommand},
        TContext
      > => {

      const mutationOptions = getPostApiUsersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const putApiUsers = (
    modifyUserRolesCommand: ModifyUserRolesCommand,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<UserDetailDto>(
      {url: `/api/Users`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: modifyUserRolesCommand
    },
      options);
    }
  


export const getPutApiUsersMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiUsers>>, TError,{data: ModifyUserRolesCommand}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiUsers>>, TError,{data: ModifyUserRolesCommand}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiUsers>>, {data: ModifyUserRolesCommand}> = (props) => {
          const {data} = props ?? {};

          return  putApiUsers(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiUsersMutationResult = NonNullable<Awaited<ReturnType<typeof putApiUsers>>>
    export type PutApiUsersMutationBody = ModifyUserRolesCommand
    export type PutApiUsersMutationError = unknown

    export const usePutApiUsers = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiUsers>>, TError,{data: ModifyUserRolesCommand}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiUsers>>,
        TError,
        {data: ModifyUserRolesCommand},
        TContext
      > => {

      const mutationOptions = getPutApiUsersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getApiUsersFulltext = (
    params?: GetApiUsersFulltextParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<UserDtoPaginatedList>(
      {url: `/api/Users/fulltext`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetApiUsersFulltextQueryKey = (params?: GetApiUsersFulltextParams,) => {
    return [`/api/Users/fulltext`, ...(params ? [params]: [])] as const;
    }

    
export const getGetApiUsersFulltextQueryOptions = <TData = Awaited<ReturnType<typeof getApiUsersFulltext>>, TError = unknown>(params?: GetApiUsersFulltextParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersFulltext>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiUsersFulltextQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUsersFulltext>>> = ({ signal }) => getApiUsersFulltext(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiUsersFulltext>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetApiUsersFulltextQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUsersFulltext>>>
export type GetApiUsersFulltextQueryError = unknown


export function useGetApiUsersFulltext<TData = Awaited<ReturnType<typeof getApiUsersFulltext>>, TError = unknown>(
 params: undefined |  GetApiUsersFulltextParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersFulltext>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiUsersFulltext>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetApiUsersFulltext<TData = Awaited<ReturnType<typeof getApiUsersFulltext>>, TError = unknown>(
 params?: GetApiUsersFulltextParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersFulltext>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiUsersFulltext>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetApiUsersFulltext<TData = Awaited<ReturnType<typeof getApiUsersFulltext>>, TError = unknown>(
 params?: GetApiUsersFulltextParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersFulltext>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useGetApiUsersFulltext<TData = Awaited<ReturnType<typeof getApiUsersFulltext>>, TError = unknown>(
 params?: GetApiUsersFulltextParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersFulltext>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetApiUsersFulltextQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getApiUsersId = (
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<UserDetailDto>(
      {url: `/api/Users/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiUsersIdQueryKey = (id: string,) => {
    return [`/api/Users/${id}`] as const;
    }

    
export const getGetApiUsersIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiUsersId>>, TError = unknown>(id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiUsersIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUsersId>>> = ({ signal }) => getApiUsersId(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiUsersId>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetApiUsersIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUsersId>>>
export type GetApiUsersIdQueryError = unknown


export function useGetApiUsersId<TData = Awaited<ReturnType<typeof getApiUsersId>>, TError = unknown>(
 id: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiUsersId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetApiUsersId<TData = Awaited<ReturnType<typeof getApiUsersId>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiUsersId>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetApiUsersId<TData = Awaited<ReturnType<typeof getApiUsersId>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useGetApiUsersId<TData = Awaited<ReturnType<typeof getApiUsersId>>, TError = unknown>(
 id: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersId>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetApiUsersIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getApiUsersCurrentUserDetail = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<UserDetailDto>(
      {url: `/api/Users/current-user-detail`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiUsersCurrentUserDetailQueryKey = () => {
    return [`/api/Users/current-user-detail`] as const;
    }

    
export const getGetApiUsersCurrentUserDetailQueryOptions = <TData = Awaited<ReturnType<typeof getApiUsersCurrentUserDetail>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersCurrentUserDetail>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiUsersCurrentUserDetailQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUsersCurrentUserDetail>>> = ({ signal }) => getApiUsersCurrentUserDetail(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiUsersCurrentUserDetail>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetApiUsersCurrentUserDetailQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUsersCurrentUserDetail>>>
export type GetApiUsersCurrentUserDetailQueryError = unknown


export function useGetApiUsersCurrentUserDetail<TData = Awaited<ReturnType<typeof getApiUsersCurrentUserDetail>>, TError = unknown>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersCurrentUserDetail>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiUsersCurrentUserDetail>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetApiUsersCurrentUserDetail<TData = Awaited<ReturnType<typeof getApiUsersCurrentUserDetail>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersCurrentUserDetail>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiUsersCurrentUserDetail>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetApiUsersCurrentUserDetail<TData = Awaited<ReturnType<typeof getApiUsersCurrentUserDetail>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersCurrentUserDetail>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useGetApiUsersCurrentUserDetail<TData = Awaited<ReturnType<typeof getApiUsersCurrentUserDetail>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersCurrentUserDetail>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetApiUsersCurrentUserDetailQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getApiUsersRoles = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<IdentityRole[]>(
      {url: `/api/Users/roles`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApiUsersRolesQueryKey = () => {
    return [`/api/Users/roles`] as const;
    }

    
export const getGetApiUsersRolesQueryOptions = <TData = Awaited<ReturnType<typeof getApiUsersRoles>>, TError = unknown>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersRoles>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiUsersRolesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUsersRoles>>> = ({ signal }) => getApiUsersRoles(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiUsersRoles>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetApiUsersRolesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUsersRoles>>>
export type GetApiUsersRolesQueryError = unknown


export function useGetApiUsersRoles<TData = Awaited<ReturnType<typeof getApiUsersRoles>>, TError = unknown>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersRoles>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiUsersRoles>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetApiUsersRoles<TData = Awaited<ReturnType<typeof getApiUsersRoles>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersRoles>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiUsersRoles>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetApiUsersRoles<TData = Awaited<ReturnType<typeof getApiUsersRoles>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersRoles>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }

export function useGetApiUsersRoles<TData = Awaited<ReturnType<typeof getApiUsersRoles>>, TError = unknown>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiUsersRoles>>, TError, TData>>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetApiUsersRolesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const putApiUsersCurrentUserChangeDetail = (
    changeUserDetailCommand: ChangeUserDetailCommand,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<UserDetailDto>(
      {url: `/api/Users/current-user-change-detail`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: changeUserDetailCommand
    },
      options);
    }
  


export const getPutApiUsersCurrentUserChangeDetailMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiUsersCurrentUserChangeDetail>>, TError,{data: ChangeUserDetailCommand}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiUsersCurrentUserChangeDetail>>, TError,{data: ChangeUserDetailCommand}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiUsersCurrentUserChangeDetail>>, {data: ChangeUserDetailCommand}> = (props) => {
          const {data} = props ?? {};

          return  putApiUsersCurrentUserChangeDetail(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiUsersCurrentUserChangeDetailMutationResult = NonNullable<Awaited<ReturnType<typeof putApiUsersCurrentUserChangeDetail>>>
    export type PutApiUsersCurrentUserChangeDetailMutationBody = ChangeUserDetailCommand
    export type PutApiUsersCurrentUserChangeDetailMutationError = unknown

    export const usePutApiUsersCurrentUserChangeDetail = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiUsersCurrentUserChangeDetail>>, TError,{data: ChangeUserDetailCommand}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiUsersCurrentUserChangeDetail>>,
        TError,
        {data: ChangeUserDetailCommand},
        TContext
      > => {

      const mutationOptions = getPutApiUsersCurrentUserChangeDetailMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const putApiUsersCurrentUserChangePassword = (
    changeUserPasswordCommand: ChangeUserPasswordCommand,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<Unit>(
      {url: `/api/Users/current-user-change-password`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: changeUserPasswordCommand
    },
      options);
    }
  


export const getPutApiUsersCurrentUserChangePasswordMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiUsersCurrentUserChangePassword>>, TError,{data: ChangeUserPasswordCommand}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiUsersCurrentUserChangePassword>>, TError,{data: ChangeUserPasswordCommand}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiUsersCurrentUserChangePassword>>, {data: ChangeUserPasswordCommand}> = (props) => {
          const {data} = props ?? {};

          return  putApiUsersCurrentUserChangePassword(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiUsersCurrentUserChangePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof putApiUsersCurrentUserChangePassword>>>
    export type PutApiUsersCurrentUserChangePasswordMutationBody = ChangeUserPasswordCommand
    export type PutApiUsersCurrentUserChangePasswordMutationError = unknown

    export const usePutApiUsersCurrentUserChangePassword = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiUsersCurrentUserChangePassword>>, TError,{data: ChangeUserPasswordCommand}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiUsersCurrentUserChangePassword>>,
        TError,
        {data: ChangeUserPasswordCommand},
        TContext
      > => {

      const mutationOptions = getPutApiUsersCurrentUserChangePasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const postApiUsersResetToken = (
    resetUserPasswordEmailCommand: ResetUserPasswordEmailCommand,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<void>(
      {url: `/api/Users/reset-token`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: resetUserPasswordEmailCommand, signal
    },
      options);
    }
  


export const getPostApiUsersResetTokenMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUsersResetToken>>, TError,{data: ResetUserPasswordEmailCommand}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiUsersResetToken>>, TError,{data: ResetUserPasswordEmailCommand}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUsersResetToken>>, {data: ResetUserPasswordEmailCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiUsersResetToken(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUsersResetTokenMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUsersResetToken>>>
    export type PostApiUsersResetTokenMutationBody = ResetUserPasswordEmailCommand
    export type PostApiUsersResetTokenMutationError = unknown

    export const usePostApiUsersResetToken = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUsersResetToken>>, TError,{data: ResetUserPasswordEmailCommand}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUsersResetToken>>,
        TError,
        {data: ResetUserPasswordEmailCommand},
        TContext
      > => {

      const mutationOptions = getPostApiUsersResetTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const postApiUsersReset = (
    resetUserPasswordCommand: ResetUserPasswordCommand,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      
      
      return customInstance<void>(
      {url: `/api/Users/reset`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: resetUserPasswordCommand, signal
    },
      options);
    }
  


export const getPostApiUsersResetMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUsersReset>>, TError,{data: ResetUserPasswordCommand}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiUsersReset>>, TError,{data: ResetUserPasswordCommand}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUsersReset>>, {data: ResetUserPasswordCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiUsersReset(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUsersResetMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUsersReset>>>
    export type PostApiUsersResetMutationBody = ResetUserPasswordCommand
    export type PostApiUsersResetMutationError = unknown

    export const usePostApiUsersReset = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUsersReset>>, TError,{data: ResetUserPasswordCommand}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUsersReset>>,
        TError,
        {data: ResetUserPasswordCommand},
        TContext
      > => {

      const mutationOptions = getPostApiUsersResetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const putApiUsersIdLock = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<UserDetailDto>(
      {url: `/api/Users/${id}/lock`, method: 'PUT'
    },
      options);
    }
  


export const getPutApiUsersIdLockMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiUsersIdLock>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiUsersIdLock>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiUsersIdLock>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  putApiUsersIdLock(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiUsersIdLockMutationResult = NonNullable<Awaited<ReturnType<typeof putApiUsersIdLock>>>
    
    export type PutApiUsersIdLockMutationError = unknown

    export const usePutApiUsersIdLock = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiUsersIdLock>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiUsersIdLock>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getPutApiUsersIdLockMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const putApiUsersIdUnlock = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      
      
      return customInstance<UserDetailDto>(
      {url: `/api/Users/${id}/unlock`, method: 'PUT'
    },
      options);
    }
  


export const getPutApiUsersIdUnlockMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiUsersIdUnlock>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiUsersIdUnlock>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiUsersIdUnlock>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  putApiUsersIdUnlock(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiUsersIdUnlockMutationResult = NonNullable<Awaited<ReturnType<typeof putApiUsersIdUnlock>>>
    
    export type PutApiUsersIdUnlockMutationError = unknown

    export const usePutApiUsersIdUnlock = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiUsersIdUnlock>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiUsersIdUnlock>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getPutApiUsersIdUnlockMutationOptions(options);

      return useMutation(mutationOptions);
    }
    