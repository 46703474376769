import {
  useGetApiUsersCurrentUserDetail,
  usePutApiUsersCurrentUserChangeDetail,
} from '@/api/backoffice/users/users';
import { getCookie } from 'cookies-next';

const usePlayer = () => {
  const accessToken = getCookie('access_token');

  const { data, isLoading, error } = useGetApiUsersCurrentUserDetail({
    query: {
      enabled: !!accessToken, // Only fetch if the user is logged in
      refetchOnWindowFocus: true, // Refetch when the window regains focus
      refetchOnMount: true, // Refetch when the component mounts
    },
  });

  // Use the Orval-generated mutation for updating the current user detail
  const { mutateAsync: updatePlayerInfo } =
    usePutApiUsersCurrentUserChangeDetail({
      mutation: {
        onError: (error: any) => {
          console.error('Failed to update player info:', error);
        },
      },
    });

  return { data, isLoading, error, updatePlayerInfo };
};

export default usePlayer;
