'use client';
import React, { InputHTMLAttributes, useState } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { twMerge } from 'tailwind-merge';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  startIcon?: React.ReactNode;
  error?: string;
}

export default function Input(props: InputProps) {
  const { label, type = 'input', startIcon, error } = props;
  const [showPassword, setShowPassword] = useState(false);
  const openPassword = (e: React.MouseEvent<SVGSVGElement>) => {
    e.preventDefault();
    setShowPassword(true);
  };

  const closePassword = (e: React.MouseEvent<SVGSVGElement>) => {
    e.preventDefault();
    setShowPassword(false);
  };

  const iconClass = 'w-5';

  return (
    <div
      className={twMerge(
        'w-full  min-w-[200px] relative rounded-sm',
        'focus-within:ring-2 focus-within:ring-blue-500',
        error ? 'ring-2 ring-error' : ''
      )}
      {...props}
    >
      {label && (
        <label className="block mb-2 text-sm text-white">{label}</label>
      )}

      <div className="relative flex flex-row bg-dark-gray p-4 rounded-md">
        {React.isValidElement(startIcon) &&
          React.cloneElement(startIcon as React.ReactElement<any>, {
            className: twMerge(iconClass, startIcon.props.className),
          })}

        <input
          className="w-full bg-transparent py-0 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-transparent sm:text-sm focus:border-t-transparent focus:outline-0 focus-visible:bg-transparent ml-2 autofill:bg-transparent"
          {...props}
          type={showPassword ? 'text' : type}
        />

        {type === 'password' &&
          (showPassword ? (
            <EyeSlashIcon
              className={iconClass}
              onMouseUp={closePassword}
              onMouseLeave={closePassword}
            />
          ) : (
            <EyeIcon
              onMouseDown={openPassword}
              onMouseLeave={closePassword}
              className={iconClass}
            />
          ))}
      </div>

      <style jsx>{`
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px #1f2937 inset !important; /* Change this to your desired background color */
          -webkit-text-fill-color: #9ca3af !important; /* Change this to your desired text color */
          transition: background-color 5000s ease-in-out 0s;
        }
      `}</style>
    </div>
  );
}
