import { ButtonHTMLAttributes, FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary';
  children: React.ReactNode;
}

const Button: FC<IButtonProps> = ({
  variant = 'none',
  className,
  children,
  ...props
}) => {
  return (
    <button
      {...props}
      className={twMerge(
        'w-full border border-solid rounded-lg font-extrabold text-sm text-center md:min-w-[220px] py-3 max-h-[58px]',
        'border-blue-gray text-blue-gray bg-component-bg',
        variant === 'primary'
          ? 'bg-light-blue border-light-blue text-black'
          : '',
        props.disabled ? 'cursor-not-allowed bg-slate-400' : 'cursor-pointer',
        className
      )}
    >
      {children}
    </button>
  );
};

export default Button;
