import axios from 'axios';
import { getCookie, setCookie } from 'cookies-next';

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

// Request interceptor to add the access token to every request
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = getCookie('access_token');
    if (accessToken && config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token refresh
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if it's a 401 error and retry hasn't been attempted yet
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = getCookie('refresh_token');

      if (refreshToken) {
        try {
          const params = new URLSearchParams();
          params.append('client_id', 'jwt.web-api');
          params.append(
            'client_secret',
            process.env.NEXT_PUBLIC_API_SECRET! || 'secret'
          );
          params.append('grant_type', 'refresh_token');
          params.append('refresh_token', refreshToken as string);

          const result = await axios.post(
            `${process.env.NEXT_PUBLIC_API_URL}/connect/token`,
            params.toString(),
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Api-Key': '1234',
              },
            }
          );

          if (result.status === 200) {
            const data = result.data;

            // Set new tokens in cookies
            const cookieOptions = {
              httpOnly: true,
              secure: process.env.NODE_ENV === 'production',
              sameSite: 'strict' as const,
              path: '/',
            };

            setCookie('access_token', data.access_token, cookieOptions);
            setCookie('refresh_token', data.refresh_token, cookieOptions);

            // Update the authorization header with the new access token
            originalRequest.headers.Authorization = `Bearer ${data.access_token}`;

            // Retry the original request with the new access token
            return axiosInstance(originalRequest);
          } else {
            console.error('Token refresh failed with status:', result.status);
            // Optionally, redirect to login or handle unauthorized access
          }
        } catch (e: unknown) {
          console.error('Error during token refresh:', e);
          // Optionally, redirect to login or handle the error
          return Promise.reject(e);
        }
      } else {
        console.error('No refresh token found.');
        // Optionally, redirect to login or handle unauthorized access
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
