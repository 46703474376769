import React from 'react';
import { useField } from 'formik';
import Input, { InputProps } from '.';
import Tooltip from '../tooltip';

const FormikInput = (props: InputProps) => {
  const [field, meta] = useField(props);
  const tooltipId = field.name + ' error';
  return (
    <>
      <Tooltip message={meta.error || ''}>
        <Input
          {...field}
          {...props}
          data-tooltip-target={tooltipId}
          error={meta.error}
        />
      </Tooltip>
    </>
  );
};

export default FormikInput;
